import React, { useEffect, useState } from 'react';
import AppRouter from 'routing/AppRouter';
import './App.scss';
import { PaymentContact } from './pages/BillingManager/interface';
import { FeatureFlagKeys, getFeatureFlagForSite } from './common/utils/featureFlagHandler';

declare global {
  interface Window {
    verifyCityRequest: boolean;
    //This object mpps is used for LoopNet payment form.
    //We are getting this from the LoopNet API so we don't need to store credit card information.
    //We only store token returned from LoopNet
    mpps: {
      buildPaymentForm: (formName: string) => void;
      createPaymentToken: (formData: {
        billingContact: PaymentContact;
        profile: string | undefined;
        referenceNumber: number;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) => Promise<any>;
      init: () => void;
      processMessage: () => void;
      validateInput: () => string[];
    };
  }
}

function injectScript(id: string, scriptText: string): void {
  if (!document.getElementById(id)) {
    const container = document.createElement('script');
    container.id = id;
    container.text = scriptText;

    document.getElementsByTagName('head')[0].appendChild(container);
  }
}

export default function App(): JSX.Element {
  const [shouldInjectMazeTrackingScript, setShouldInjectMazeTrackingScript] = useState(false);
  useEffect(() => {
    getFeatureFlagForSite(FeatureFlagKeys.InjectMazeTrackingScript).then(flagValue => {
      if (flagValue !== shouldInjectMazeTrackingScript) {
        setShouldInjectMazeTrackingScript(flagValue);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  injectScript(
    'google_tag_manager_init',
    `(function(w,d,s,l,i) {
          w[l]=w[l]||[];
          w[l].push({
            'gtm.start':new Date().getTime(),
            event:'gtm.js'
          });
          var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),
            dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-55GPT5S');
      `
  );

  if (shouldInjectMazeTrackingScript) {
    injectScript(
      'maze_tracking_codes_init',
      `(function (m, a, z, e) {
              var s, t;
              try {
                t = m.sessionStorage.getItem('maze-us');
              } catch (err) {}

              if (!t) {
                t = new Date().getTime();
                try {
                  m.sessionStorage.setItem('maze-us', t);
                } catch (err) {}
              }

              s = a.createElement('script');
              s.src = z + '?apiKey=' + e;
              s.async = true;
              a.getElementsByTagName('head')[0].appendChild(s);
              m.mazeUniversalSnippetApiKey = e;
            })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', 'd8525713-f258-4416-869c-f9b748eb747f');
        `
    );
  }

  return (
    <div data-testid="app" className="app-container">
      <AppRouter />
    </div>
  );
}
