import { useContext, useEffect, useRef, useState } from 'react';
import { INPUT_TYPES } from 'components/common/Form/types/enums';
import { ValidationContext } from '../FormDataProvider/FormDataProvider';
import { fetchLandApiData } from 'components/common/utils/helpers';
import { Validator } from '../types/types';

const regexIsEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexHasEmail = /\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}\b/;
const regexHasUrl =
  /(((http(s)?:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/m;
const regexValidUrl = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]+)([\/\w .-]*)?(\?[=&\w%-]*)?(#[/\w]*)?$/i;
const regexHtml = /<[^>].+>/;
const regexIsZip = /^(?!00000)(?<zip>(?<zip5>\d{5})(?:[ -](?=\d))?(?<zip4>\d{4})?)$/;
const regexOverTwoDecimals = /\.\d{3}/;
const regexIsAlphaNumeric = /^[a-zA-Z0-9]*$/;
export const regexIsPhone = /^(?:\+?1[-. ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

// validator function returns true if passes validation.
type ValidatorType = (value: string) => boolean | Promise<boolean>;

export enum ValidationMode {
  Loose,
  Strict
}

// The validation system uses "rules" to validate input fields.
// The rules return booleanOrPromise. True means the field's value passed the validation rule.
// False means the field's value failed the validation rule.
export interface Rule {
  id: string;
  message: string;
  validator: ValidatorType;
  mode?: ValidationMode;
}

// Validates a single validation rule for a field.
export async function validateFieldRule(rule: Rule, value: string): Promise<[boolean, string]> {
  const result = await rule.validator(value);
  return [result, rule.message];
}

// Validates all validation rules for a single field.
export async function validateFieldRules(rules: Rule[], value: string): Promise<[boolean, string][]> {
  return await Promise.all(rules.map(rule => validateFieldRule(rule, value)));
}

// ruleFactory returns "canned" validation rules and allows the canned ruless messages to be overridden.
// For example, the numeric rule has a default message of "Please enter a number," but we may want
// the error message for a particular field to be, "Acres must be a number."
export const ruleFactory = {
  // Custom rule factory. Provide a message to be displayed on failure and a function that returns a boolean or a Promise<boolean>.
  // Validator returns true for passing or false for failing.
  // id must be unique per Form.
  custom: (message: string, validator: ValidatorType, id: string): Rule => {
    return {
      id,
      message,
      validator
    };
  },

  numeric: (message = 'Please enter a number. '): Rule => {
    return {
      id: 'numeric',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        for (let i = 0; i < value.length; i++) {
          if (value[i] !== '.' && isNaN(parseInt(value[i], 10))) {
            return false;
          }
        }
        return true;
      }
    };
  },

  alphaNumeric: (message = 'Please enter an alphanumeric value. '): Rule => {
    return {
      id: 'alphaNumeric',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return regexIsAlphaNumeric.test(value);
      }
    };
  },

  // The value, if present, must be formatted like an email address.
  emailFormat: (message = 'Please enter an email address in a valid format. '): Rule => {
    return {
      id: 'emailFormat',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return regexIsEmail.test(value);
      }
    };
  },

  // the value is a zip code
  isZip: (message = 'This field must be a valid US zip code.'): Rule => {
    return {
      id: 'zipFormat',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return regexIsZip.test(value);
      }
    };
  },

  // The value must be present.
  required: (message = 'This field is required.', mode: ValidationMode = ValidationMode.Strict): Rule => {
    return {
      id: 'required',
      message,
      validator: (value: string): boolean => !!value,
      mode
    };
  },

  minLength: (minVal: number, message = ''): Rule => {
    return {
      id: 'minLength',
      message: message || `The value must be at least ${minVal} characters long. `,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return value.length >= minVal;
      }
    };
  },

  maxValue: (maxVal: number, message = ''): Rule => {
    return {
      id: 'maxValue',
      message: message || 'This value must be at most ' + maxVal + '. ',
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return parseFloat(value) <= maxVal;
      }
    };
  },

  minValue: (minVal: number, message = ''): Rule => {
    return {
      id: 'minValue',
      message: message || 'This value must be ' + minVal + ' or greater. ',
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return parseFloat(value) >= minVal;
      }
    };
  },

  //This is specific to the current build of Marketplace Hub. The $2 price is associated with Auctions, which we are not doing in this phase 3/17/21
  notAuction: (message = 'This value must not equal 2. '): Rule => {
    return {
      id: 'notAuction',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }

        return parseFloat(value) !== 2;
      }
    };
  },

  noDecimal: (message = 'The value must not contain a decimal. '): Rule => {
    return {
      id: 'noDecimal',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return !value.includes('.');
      }
    };
  },

  maxTwoDecimals: (message = 'The value must not have more than two decimal places. '): Rule => {
    return {
      id: 'maxTwoDecimals',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return !regexOverTwoDecimals.test(value);
      }
    };
  },

  noEmail: (message = 'The value must not contain an email address. '): Rule => {
    return {
      id: 'noEmail',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return !regexHasEmail.test(value);
      }
    };
  },

  noUrl: (message = 'The value must not contain a url. '): Rule => {
    return {
      id: 'noUrl',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return !regexHasUrl.test(value) || regexHasEmail.test(value);
      }
    };
  },

  noHtml: (message = 'The value must not contain HTML. '): Rule => {
    return {
      id: 'noHTML',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return !regexHtml.test(value);
      }
    };
  },

  maxDate: (maxDate: string, message = `The date must not be after ${maxDate}. `): Rule => {
    return {
      id: 'maxDate',
      message,
      validator: (value): boolean => {
        if (!value) {
          return true;
        }
        return new Date(maxDate) >= new Date(value);
      }
    };
  },

  isUrl: (message = `This is not a valid URL. `, customRegex?: RegExp): Rule => {
    return {
      id: 'URL',
      message,
      validator: function (value): boolean {
        try {
          if (!value) {
            // let another validator validate required input
            return true;
          }

          let url = value.trim();
          if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url;
          }

          if (!regexValidUrl.test(url)) {
            return false;
          }

          if (customRegex && !customRegex.test(url)) {
            return false;
          }

          new URL(url);
          return true;
        } catch (err) {
          return false;
        }
      }
    };
  },

  isReachableUrl: (message = `Please provide a reachable url. `): Rule => {
    return {
      id: 'reachableURL',
      message,
      validator: async function (value): Promise<boolean> {
        try {
          if (!value) {
            return true;
          }

          // the simple fetch to random urls will not work
          //past Cors Policy
          const response = await fetchLandApiData<boolean>('ReachUrl', `/DraftListing/validate-url/?url=${value}`);

          if (response.ok) {
            return response.data;
          }
          return false;
        } catch (err) {
          return false;
        }
      }
    };
  },

  isValidFileType: (allowedTypes: string[], fileName: string, fileType: string): Rule => {
    return {
      id: 'fileTypeValidation',
      message: `${fileName} is not a valid ${fileType} type.`,
      validator: (value): boolean => {
        for (let i = 0; i < allowedTypes.length; i++) {
          if (value === allowedTypes[i]) {
            return true;
          }
        }

        return false;
      }
    };
  },

  isValidFileSize: (fileName: string, sizeInMb: number): Rule => {
    const Mb = 1024 * 1024;

    return {
      id: 'fileSizeValidation',
      message: `${fileName} exceeds ${sizeInMb}MB file size.`,
      validator: (value): boolean => (value ? parseFloat(value) <= sizeInMb * Mb : false)
    };
  },

  isValidCropDimension: (): Rule => {
    return {
      id: 'cropValidation',
      message: 'Crop is out of bounds.',
      validator: (value): boolean => {
        const v = parseFloat(value);
        return v >= 0 && v <= 1;
      }
    };
  },

  isValidEmbed: (): Rule => {
    return {
      id: 'embedValidation',
      message: 'Embed code / URL is not valid.',
      validator: (value): boolean => {
        if (!value) {
          return true;
        }

        return regexHasUrl.test(value) || regexHtml.test(value);
      }
    };
  }
} as const;

// TODO: determine defaults for all types
export const DEFAULT_VALIDATION_RULES = (inputType: INPUT_TYPES): Rule[] => {
  switch (inputType) {
    case INPUT_TYPES.TEXT:
      return [ruleFactory.noEmail(), ruleFactory.noHtml(), ruleFactory.noUrl()];
    case INPUT_TYPES.NUMBER:
      return [];
    case INPUT_TYPES.DATE:
      return [];
    case INPUT_TYPES.EMAIL:
      return [ruleFactory.emailFormat()];
    case INPUT_TYPES.URL:
      return [ruleFactory.isUrl()];
    case INPUT_TYPES.EMBED:
      return [ruleFactory.isValidEmbed()];

    default:
      return [];
  }
};

export const COMMON_VALIDATION_RULES = (commonType: string): Rule[] => {
  switch (commonType.toLowerCase()) {
    case 'price':
      return [
        ruleFactory.noDecimal(),
        ruleFactory.minValue(0),
        ruleFactory.maxValue(1000000000),
        ruleFactory.notAuction()
      ];

    case 'acres':
      return [ruleFactory.minValue(0), ruleFactory.maxValue(999999.99), ruleFactory.maxTwoDecimals()];

    case 'sqft':
      return [ruleFactory.minValue(0), ruleFactory.maxValue(1000000), ruleFactory.noDecimal()];

    default:
      return [];
  }
};

type ValidationResult = { value: string; result: boolean; mode: ValidationMode; errors: string[] };

export function useClientValidation(
  value: string,
  source: string,
  rules: Rule[],
  type: INPUT_TYPES,
  commonType: string
): [string[], () => Promise<boolean>, () => void, () => Promise<boolean>] {
  const { validators, pendingValidations, validationErrors } = useContext(ValidationContext);
  const [errorMessages, setErrorMessages] = useState([] as string[]);
  const validationResultMemoRef = useRef<ValidationResult>();

  useEffect(() => {
    validationResultMemoRef.current = undefined;
  }, [source, type, commonType]);

  const updateErrors = (errors: string[]): void => {
    setErrorMessages(errors);
    validationErrors[source] = errors;
  };

  async function validateRules(mode: ValidationMode): Promise<boolean> {
    // For strict mode we run all rules, otherwise run only loose (default) rules
    const modeRules =
      mode === ValidationMode.Strict ? rules : rules.filter(r => r.mode === ValidationMode.Loose || !r.mode);
    const errors = (
      await validateFieldRules(
        overrideDefaultRules(modeRules, [...COMMON_VALIDATION_RULES(commonType), ...DEFAULT_VALIDATION_RULES(type)]),
        value
      )
    )
      .filter(validation => !validation[0])
      .map(validation => validation[1]);

    updateErrors(errors);

    const result = errors.length > 0 ? false : true;
    validationResultMemoRef.current = { value, result, mode, errors: errors };

    return result;
  }

  async function statefulValidator(mode: ValidationMode = ValidationMode.Strict): Promise<boolean> {
    // runs the validation any time the field is blurred

    // Register the promise before awaiting it so any other components using the ValidationContext
    // can track pending validations if needed.
    const validationPromise = validateRules(mode);

    pendingValidations[source] = validationPromise;

    const result = await validationPromise;

    // Remove the validation promise after it's completed.
    delete pendingValidations[source];

    return result;
  }

  async function validator(mode: ValidationMode = ValidationMode.Strict): Promise<boolean> {
    // No need to rerun the validation if the value hasn't changed--return the memoized result.
    const validationResultMemo = validationResultMemoRef.current;
    if (validationResultMemo && validationResultMemo.value === value && validationResultMemo.mode === mode) {
      updateErrors(validationResultMemo.errors);
      return validationResultMemo.result;
    }
    return statefulValidator(mode);
  }

  validators[source] = validator;

  function clearValidations(): void {
    setErrorMessages([]);
    delete validationErrors[source];
  }

  return [errorMessages, validator, clearValidations, statefulValidator];
}

// allows for user rules passed in as props to take precedence over common and default rules
export function overrideDefaultRules(overrideRules: Rule[], toBeFiltered: Rule[]): Rule[] {
  const filtered = overrideRules.reduce((acc, next) => acc.filter(item => item.id != next.id), toBeFiltered);
  return [...overrideRules, ...filtered];
}

const runValidations = (
  validators: Record<string, Validator>,
  mode: ValidationMode = ValidationMode.Strict
): Promise<boolean[]> => Promise.all(Object.values(validators).map(validator => validator(mode)));

export const enforceStrictValidation = (validators: Record<string, Validator>): Promise<boolean[]> =>
  runValidations(validators);

export const enforceLooseValidation = (validators: Record<string, Validator>): Promise<boolean[]> =>
  runValidations(validators, ValidationMode.Loose);

export { useClientValidation as default };
