import { useState, Fragment, useRef, useEffect, useCallback } from 'react';
import { FeatureFlagKeys, getFeatureFlagForUser } from 'components/common/utils/featureFlagHandler';
import SimpleMap from 'components/common/SimpleMap/SimpleMap';
import SimpleMapNew from 'components/common/SimpleMapNew';
import { Button } from 'components/common/Button/Button';
import PlacesAutocomplete, { AddressResponse, Place } from 'components/common/Form/PlacesAutocomplete';
import useMapLoader from 'components/common/SimpleMap/useMapLoader';
import { ContextValue, useCtx } from 'components/common/Form/FormDataProvider/FormDataProvider';
import { DefaultFormStateType } from 'components/common/Form/types/types';
import styles from './AddListingAddress.module.scss';
import Modal from 'components/common/Modal';
import { postToLandApi } from 'components/common/utils/helpers';
import { ListingFormData } from 'components/pages/Listing/defaultData';
import { useHistory } from 'react-router';
import AddressEditGroup from 'components/pages/Listing/PropertyAddress/AddressEditGroup';
import { MemoryRouter } from 'react-router-dom';
import { useStore } from 'components/store';
import type { Parcel } from '@costar/land-ui-components';
import { createParcelsOverlayData, firstSaveOfMapMarker, addMultipleOverlays } from './AddListingAddressApi';

export default function AddListingAddress(): JSX.Element {
  const isMapLoaded = useMapLoader();
  const [provided, setProvided] = useCtx() as ContextValue<DefaultFormStateType<Place>>;
  const location = provided?.place?.geometry?.location;
  const viewport = provided?.place?.geometry?.viewport;
  const [isExpanded, setIsExpanded] = useState(true);
  const [displayMap, setDisplayMap] = useState(true);
  const [isListingFlowActive, setListingFlowState] = useStore(state => [
    state.isListingFlowActive,
    state.setListingFlowState
  ]);
  const history = useHistory();
  const addressRef = useRef<AddressResponse>();
  const mapRef = useRef<google.maps.LatLngLiteral>();
  const [addy, setaddy] = useState({} as AddressResponse);
  const [, setMarker] = useState({} as google.maps.LatLngLiteral);
  const [isPosting, setIsPosting] = useState(false);
  const [showNewMapFlag, setShowNewMapFlag] = useState(false);
  const [selectedParcels, setSelectedParcels] = useState<Parcel[]>([]);

  useEffect(() => {
    getFeatureFlagForUser(FeatureFlagKeys.ShowNewMapOnLongForm).then((flagValueResponse: boolean): void => {
      if (flagValueResponse !== showNewMapFlag) {
        setShowNewMapFlag(flagValueResponse);
      }
    });
  }, [showNewMapFlag]);

  const onAddressUpdate = (addressResponse: AddressResponse): void => {
    addressRef.current = addressResponse;
    setaddy(addressResponse);
  };

  const handleEditAddress = (): void => setDisplayMap(!displayMap);

  // This is the first save and sent to create a new listing.
  const handleSaveAddress = async (val: unknown, newpos: unknown): Promise<void> => {
    setIsPosting(true);
    const response = await postToLandApi<ListingFormData>('/Listing/add-listing', val);

    if (response.ok) {
      const listingId = response.data as unknown as number;

      // Save 1st Marker posistion from simple map to the db after response
      await firstSaveOfMapMarker(listingId, newpos as google.maps.LatLngLiteral);

      // Only submit overlays if there are selected parcels
      if (selectedParcels.length > 0) {
        const overlayDataRequest = createParcelsOverlayData(
          selectedParcels.map(parcel => ({ parcelId: parcel })),
          listingId
        );
        await addMultipleOverlays(overlayDataRequest);
      }
      setIsExpanded(false);
      toggleAddModal(false);
      setIsPosting(false);
      history.push(`/listing/edit/${response.data}`);
    } else {
      console.error(response.errors);
      setIsPosting(false);
    }
  };

  const toggleAddModal = (isActiveOverride?: boolean): void => {
    setListingFlowState(typeof isActiveOverride === 'boolean' ? isActiveOverride : !isListingFlowActive);
  };

  const closeModal = (): void => {
    setIsExpanded(false);
    toggleAddModal(false);
    onAddressUpdate({} as AddressResponse);
    setProvided(state => ({ ...state }) as DefaultFormStateType<Place>);
  };

  const returnMainModal = (): void => {
    handleEditAddress();
  };

  const saveMarker = (newpos: google.maps.LatLngLiteral): void => {
    setMarker(newpos);
    const mapMarker = {
      lat: newpos.lat,
      lng: newpos.lng
    } as unknown as google.maps.LatLngLiteral;
    mapRef.current = mapMarker;
  };

  const handleSelectedParcelsChange = useCallback(
    (parcels: Parcel[]): void => {
      const isSame = JSON.stringify(parcels) === JSON.stringify(selectedParcels);
      if (!isSame) {
        setSelectedParcels(parcels);
      }
    },
    [selectedParcels]
  );

  return (
    <div>
      {!displayMap && (
        <Modal title="Add Your Listing" close={returnMainModal} isExpanded={isExpanded}>
          <AddressEditGroup address={addy} />
        </Modal>
      )}
      {displayMap && (
        <Modal title="Add Your Listing" close={closeModal} isExpanded={isExpanded}>
          <Fragment>
            <PlacesAutocomplete isMapApiLoaded={isMapLoaded} onUpdate={onAddressUpdate} />
            <div className={styles['edit-link']}>
              <Button kind="text" onClick={handleEditAddress} isDisabled={addressRef.current?.address == null}>
                Edit Address
              </Button>
            </div>
            {showNewMapFlag ? (
              <SimpleMapNew
                isMapApiLoaded={isMapLoaded}
                location={location as unknown as google.maps.LatLngLiteral}
                viewport={viewport as unknown as google.maps.LatLngBoundsLiteral}
                saveMarker={saveMarker}
                addressLat={addressRef.current?.latitude}
                addressLng={addressRef.current?.longitude}
                selectedParcels={selectedParcels}
                setSelectedParcels={setSelectedParcels}
                onSelectedParcelsChange={handleSelectedParcelsChange}
              />
            ) : (
              <SimpleMap
                isMapApiLoaded={isMapLoaded}
                location={location as unknown as google.maps.LatLngLiteral}
                saveMarker={saveMarker}
              />
            )}
            <div className="button-container-center">
              <MemoryRouter>
                <Button
                  className={styles.button}
                  isDisabled={isPosting || addressRef.current?.address == null}
                  onClick={(): void => {
                    if (mapRef.current === undefined) {
                      const mapMarker = {
                        lat: addressRef.current?.latitude,
                        lng: addressRef.current?.longitude
                      } as unknown as google.maps.LatLngLiteral;
                      mapRef.current = mapMarker;
                    }
                    handleSaveAddress(addressRef.current, mapRef.current);
                  }}
                >
                  Get Started
                </Button>
              </MemoryRouter>
            </div>
          </Fragment>
        </Modal>
      )}
    </div>
  );
}
