import { defaultFormState } from 'components/common/Form/types/types';
import { MarketStatus } from 'components/types/enums';

export const matterport = 'matterport';
export const video = 'video';
export const markerPosition = 'markerPosition';

export const MinLatitude = 18;
export const MaxLatitude = 72;
export const MinLongitude = -180;
export const MaxLongitude = -64;

export interface Activity {
  activityId: number;
  label: string;
  value: boolean;
}

// Represents an Amenity from the DB
// displayType is the expected input type (checkbox, selectbox, input, textarea)
// It is possible that an Amenity may differ from its AmenityCategory's display type
// This most commonly happens when an Other text input is in a category of checkboxes
export interface Amenity {
  displayType: string;
  id: string;
  name: string;
  value: string;
}

// represents an AmenityCategory from the DB and the Amenities under that category
// displayType is the expected input type (checkbox, selectbox, input, textarea)
// for the Amenities so we know whether to draw them as a select input or as the
// input type defined on the Amenity
export interface AmenityCategory {
  amenities: Amenity[];
  displayType: string;
  id: string;
  isStandard: boolean;
  name: string;
}

export interface LatLong {
  latitude: number;
  longitude: number;
}

export interface Media {
  url: string;
  embedUrl: string;
}

export interface Attachment {
  documentId: number;
  id: number;
  filename: string;
}

export enum OverlayType {
  Marker = 1,
  Line = 2,
  Polygon = 3
}

export interface PropertyOverlay {
  description: string;
  geometry: string;
  mainParcel: boolean;
  name: string;
  overlayId: number;
  overlayTypeId: OverlayType;
  propertyId: number;
  pType: string;
  styleString: string;
  uuid: string;
  zIndex: number;
}

export interface Photo {
  documentId: number;
  imageId: number;
  label: string;
}

export interface PropertyType {
  type: number;
  label: string;
  value: boolean;
}

export interface DraftListing {
  // section Activities
  activities: Activity[];

  // section Amenities
  amenityCategories: AmenityCategory[];

  // section Auction
  auctionId?: number;

  // section BasicInfo
  acres?: number;
  beds?: number;
  fullBaths?: number;
  halfBaths?: number;
  hasHouse?: boolean;
  homeSqft?: number;
  mlsId: string;
  ownerFinancing?: boolean;
  price?: number;

  // section Documents and Links
  documents: Attachment[];
  externalSite: string;

  // section MetaData
  displayErrors: string[];
  displaySuccess: string[];
  lastEdited: Date;
  listingId: number;
  published: boolean;
  isSaving: boolean;
  // fat fetch response.status
  responseStatus: number;

  // section PropertyAddress
  address: string;
  city: string;
  cityId?: number;
  county: string;
  countyId?: number;
  latitude: number;
  longitude: number;
  mapMarker: LatLong;
  stateId: number;
  zip: string;

  //section detailMap data
  countyFips: number;
  cityLat: number;
  cityLong: number;
  stateAbbreviation: string;
  geocodeAccuracy: number;
  thirdPartyMapUrl: string;

  // section PropertyInfo
  description: string;
  title: string;
  referenceName: string;

  //section detailMap Overlays
  propertyOverlays: PropertyOverlay[];

  // section PropertyType
  propertyTypes: PropertyType[];

  // section Photos
  // todo: Jian/Corean, help me figure out when/where this is used.
  thumbnailId?: number;
  photos: Photo[];

  // section Videos
  matterport: Media;
  video: Media;

  // section Title
  comments: string | undefined;
  listingDate: Date;
  status: MarketStatus;
  trackingPhoneNumber: string;
}

export const initialListingData: DraftListing = {
  // section Activities
  activities: [] as Activity[],

  // section Amenities
  amenityCategories: [] as AmenityCategory[],

  // section Auction
  auctionId: undefined,

  // section BasicInfo
  acres: undefined,
  beds: 0,
  fullBaths: 0,
  halfBaths: 0,
  hasHouse: false,
  homeSqft: 0,
  mlsId: '',
  ownerFinancing: false,
  price: undefined,

  // section Documents and Links
  documents: [],
  externalSite: '',

  // section fat-fetch
  responseStatus: 200,

  // section MetaData
  displayErrors: [],
  displaySuccess: [],
  lastEdited: new Date(),
  listingId: 0,
  published: false,
  //isSaving is used to disable/enable Listing page publish button
  isSaving: false,

  // section PropertyAddress
  address: '',
  city: '',
  cityId: undefined,
  county: '',
  countyId: undefined,
  latitude: 0,
  longitude: 0,
  mapMarker: {
    latitude: 0,
    longitude: 0
  },
  stateId: 0,
  zip: '',

  // section PropertyInfo
  description: '',
  title: '',
  referenceName: '',

  //section detailMap Data
  countyFips: 0,
  cityLat: 0,
  cityLong: 0,
  stateAbbreviation: '',
  geocodeAccuracy: 0,
  thirdPartyMapUrl: '',

  //section detailMap overlays
  propertyOverlays: [] as PropertyOverlay[],

  // section PropertyType
  propertyTypes: [] as PropertyType[],

  // section Photos
  thumbnailId: undefined,
  photos: [] as Photo[],

  // section Videos
  matterport: {
    url: '',
    embedUrl: ''
  },
  video: {
    url: '',
    embedUrl: ''
  },

  // section Title
  comments: undefined,
  listingDate: new Date(),
  status: 0,
  trackingPhoneNumber: ''
};

export const initialFormData = defaultFormState(initialListingData);
export type ListingFormData = typeof initialFormData;
