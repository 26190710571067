import { parcelOverlayStyleData } from '@costar/land-ui-components';
import { postToLandApi } from 'components/common/utils/helpers';
import type { Parcel } from '@costar/land-ui-components';
import type { ListingFormData } from 'components/pages/Listing/defaultData';

export interface MultipleOverlaysDataRequest {
  overlays: PropertyOverlay[];
}

export interface MultipleOverlaysDataResult {
  overlays: PropertyOverlay[];
  status: string;
  errorMessage?: string;
}

export interface PropertyOverlay {
  geometry: string;
  info: {
    name: string;
    ptype: string;
  };
  media: unknown[];
  property_id: number;
  style: {
    fillColor: string;
    icon: string;
    fillOpacity: string;
    strokeWeight: string;
  };
  type: number;
  zIndex: number;
}

export const createParcelsOverlayData = (
  selectedParcels: { parcelId: Parcel }[],
  listingId: number
): MultipleOverlaysDataRequest => {
  return {
    overlays: selectedParcels.map(parcel => ({
      geometry: parcel.parcelId.lines[0],
      info: {
        name: 'Main Parcel',
        ptype: 'parcel'
      },
      media: [],
      property_id: listingId,
      style: parcelOverlayStyleData,
      type: 3,
      zIndex: 1000
    }))
  };
};

export const firstSaveOfMapMarker = async (
  listingId: number,
  location: google.maps.LatLngLiteral | undefined
): Promise<void> => {
  await postToLandApi<ListingFormData>(
    '/Listing/add-simple-marker',
    { ListingId: listingId, position: location } as unknown,
    'addMarker'
  );
};

export const addMultipleOverlays = async (overlayDataRequest: MultipleOverlaysDataRequest): Promise<void> => {
  await postToLandApi<MultipleOverlaysDataResult>('/detailsmap/addoverlays', overlayDataRequest);
};
