import { fetchLandApiData, postToLandApi } from './helpers';

export interface FeatureFlagInfo {
  siteId: number;
  flag: string;
  value: boolean;
}

export enum FeatureFlagKeys {
  Auction = 'show_auction_details_on_marketing_hub_long_form',
  ShowListingSpecificAnalyticsPage = 'show_listing_specific_analytics_page',
  InjectMazeTrackingScript = 'inject_maze_tracking_script',
  UseStripePaymentMH = 'configuration_use_stripe_for_payment_token_in_marketing_hub',
  ShowNewMapOnLongForm = 'show_new_map_on_marketing_hub_long_form'
}

export const defaultFeatureFlags: Record<FeatureFlagKeys, boolean> = {
  [FeatureFlagKeys.Auction]: false,
  [FeatureFlagKeys.ShowListingSpecificAnalyticsPage]: false,
  [FeatureFlagKeys.InjectMazeTrackingScript]: false,
  [FeatureFlagKeys.UseStripePaymentMH]: false,
  [FeatureFlagKeys.ShowNewMapOnLongForm]: false
};

export const getFeatureFlagForUser = async (flagKey: FeatureFlagKeys, customAttributes = []): Promise<boolean> => {
  try {
    const response = await postToLandApi<boolean>(`/featureflag/${flagKey}/3000/user`, customAttributes);

    if (response.ok) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const getFeatureFlagForSite = async (flagKey: FeatureFlagKeys): Promise<boolean> => {
  try {
    const response = await fetchLandApiData<boolean>('', `/featureflag/${flagKey}/3000`);

    if (response.ok) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const getAllFeatureFlagsForSite = async (): Promise<Record<FeatureFlagKeys, boolean>> => {
  try {
    const response = await fetchLandApiData<Record<FeatureFlagKeys, boolean>>('', `/featureflag/all/3000`);

    if (response.ok) {
      const featureFlags = response.values as Record<FeatureFlagKeys, boolean>;
      return featureFlags;
    }
  } catch (error) {
    console.error(error);
  }

  return defaultFeatureFlags;
};
