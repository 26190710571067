export enum INPUT_TYPES {
  DATE = 'date',
  DECIMAL = 'decimal',
  EMAIL = 'email',
  EMBED = 'embed',
  FILE = 'file',
  HIDDEN = 'hidden',
  IMAGE = 'image',
  NONE = 'none',
  NUMBER = 'number',
  PASSWORD = 'password',
  SEARCH = 'search',
  SUBMIT = 'submit',
  TEL = 'tel',
  TEXT = 'text',
  URL = 'url'
}

export enum INPUT_MODES {
  DECIMAL = 'decimal',
  EMAIL = 'email',
  NONE = 'none',
  NUMERIC = 'numeric',
  SEARCH = 'search',
  TEL = 'tel',
  TEXT = 'text',
  URL = 'url'
}
