import { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { addMinutesToDate } from 'components/common/utils/helpers';
import { BasicUserInfo, LoggedInStatus, LoggedInExpiration, NewUserInfo } from '../interfaces';
import { AllStoreSlices, AuthSlice } from '../StoreSlices';
import { FeatureFlagKeys, getAllFeatureFlagsForSite, defaultFeatureFlags } from 'components/common/utils/featureFlagHandler';

export const createAuthSlice: StateCreator<
  AllStoreSlices,
  [['zustand/immer', never]],
  [['zustand/immer', never]],
  AuthSlice
> = immer((set, get) => ({
  // Auth State
  loginState: {
    status: LoggedInStatus.unknown,
    user: NewUserInfo,
    expires: new Date(0)
  },
  user: NewUserInfo,
  isLoggedIn: false,
  featureFlags: defaultFeatureFlags,
  // Auth Actions
  getIsLoginValid: (): boolean => {
    const { expires, status } = get().loginState;
    const currentDate = new Date();
    const expireDate = expires;
    const loggedIn = status === LoggedInStatus.loggedIn;
    return loggedIn && currentDate < expireDate;
  },
  setLoginState: (user: BasicUserInfo, flags: Record<FeatureFlagKeys, boolean>): void => {
    set(state => {
      state.loginState = {
        status: LoggedInStatus.loggedIn,
        user,
        expires: addMinutesToDate(new Date(), LoggedInExpiration)
      };
      state.user = user;
      state.isLoggedIn = true;
      state.featureFlags = flags;
    });
  },
  login: (user: BasicUserInfo): void => {
    getAllFeatureFlagsForSite().then((flags: Record<FeatureFlagKeys, boolean>): void => {
      get().setLoginState(user, flags);
    });
  },
  logout: (): void =>
    set(state => {
      state.loginState = {
        status: LoggedInStatus.loggedOut,
        user: NewUserInfo,
        expires: addMinutesToDate(new Date(), LoggedInExpiration)
      };
      state.isLoggedIn = false;
    })
}));
